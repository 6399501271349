import React from "react"
import { useLocalization } from "gatsby-theme-i18n"
import { navigate } from "gatsby"
import { Layout } from "@bw/layouts"
import { Section, Button } from "@bw/bits"
import { UserConnect } from "@bw/partials"
import { FormField, Input } from "@bw/forms"
import { resetPassword } from "@actions/user"
import { useUser } from "@contexts/user"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { Formik, Form, Field } from "formik"
import * as yup from "yup"
import YupPassword from "yup-password"
YupPassword(yup)

function ResetPassword({ pageContext }) {
  const { localizedPath, defaultLang } = useLocalization()
  const { locale } = pageContext
  const { t } = useTranslation()
  const [user] = useUser()

  if (typeof window === "undefined") {
    return null
  }

  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get("token")

  if (user.loggedIn) {
    navigate(
      localizedPath({
        defaultLang,
        locale,
        path: `/user/${user.publicKey}/`,
      })
    )
    return null
  }

  if (!token) {
    navigate(
      localizedPath({
        defaultLang,
        locale,
        path: `/user/login/`,
      })
    )
    return null
  }

  return (
    <Layout {...{ pageContext }}>
      <Section
        title={t("user.form.reset.title")}
        subtitle={t("user.form.reset.subtitle")}
      />
      <Section>
        {!user.publicKey ? (
          <UserConnect />
        ) : (
          <Formik
            validationSchema={yup.object().shape({
              email: yup.string().required().email(),
              password: yup
                .string()
                .password()
                .minLowercase(1)
                .minUppercase(1)
                .minNumbers(1)
                .minSymbols(1)
                .min(12)
                .required(),
              password_confirmation: yup
                .string()
                .oneOf([yup.ref("password"), null], "Passwords must match"),
            })}
            initialValues={{
              email: "",
              password: "",
              password_confirmation: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              resetPassword({
                ...values,
                lang: locale,
                token,
              })
                .then(() => {
                  navigate(
                    localizedPath({
                      defaultLang,
                      locale,
                      path: `/user/login/`,
                    })
                  )
                })
                .catch(err => {
                  toast.error(err.response.data.message)
                })
                .finally(() => {
                  setSubmitting(false)
                })
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ width: "316px" }} data-cy="reset-form">
                <FormField name="email" title={t("user.form.email")}>
                  <Field name="email" type="email" component={Input} />
                </FormField>
                <FormField name="password" title={t("user.form.password")}>
                  <Field name="password" type="password" component={Input} />
                </FormField>
                <FormField
                  name="password_confirmation"
                  title={t("user.form.repeatpassword")}
                >
                  <Field
                    name="password_confirmation"
                    type="password"
                    component={Input}
                  />
                </FormField>
                <p>
                  <Button
                    submit
                    data-cy="forgot-submit"
                    label={t("user.form.reset.button")}
                    disabled={isSubmitting}
                  />
                </p>
              </Form>
            )}
          </Formik>
        )}
      </Section>
    </Layout>
  )
}

export default ResetPassword
